import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const candidateHomePage = {
    mobile: {
        appliedJobs: {
            overflow: "hidden",
            marginTop: "18.11vw",
            position: "relative",
            zIndex: "1",
            titleWrapper: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            title: {
                textAlign: "left" as "left",
                marginLeft: "4.83vw",            
                fontWeight: "bold" as "bold",            
                fontSize: "6.03vw",                    
                letterSpacing: "0px",
                color: Color.LightBlack,
                marginTop: "auto",
                marginBottom: "auto",
                opacity: "1"
            },
            redirectButton: {
                display: "flex",                
                padding: "0",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                color: Color.Blue,
                fontWeight: "bold",
                fontSize: "3.86vw",
                margin: "auto 4.83vw auto auto",
                icon: {
                    fill: Color.Blue,
                    marginLeft: "1.5vw"
                }  
            },
            slider: {
                margin: "4.83vw -4.83vw 3.2vh 4.83vw",
                width: "152.17vw"
            },
            grid: {
                display: "grid",
                gridTemplateColumns: "repeat(1, auto)",
                margin: "4.83vw auto 0 4.83vw"
            }
        }, 
        dots: { 
            marginTop: "0%", 
            marginBottom: "0%", 
            marginLeft: "-41%",
            padding: "0",
            color: Color.Black
        },
        application: {
            height: "47.10vw",
            width: "74.87vw",
            borderRadius: "2.41vw",
            marginBottom: "2.41vw",
            transition: "all 0.3s ease-in-out",
            display: "inline-block",
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",
            hover: {
                background: Color.White,
                boxShadow: "5px 5px 45px #00000029"
            },
            leave: {
                background: Color.LightAsh
            },
            link: {
                textDecoration: "none"
            },
            wrapper: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: "3.62vw"
            },
            logoWrapper: {
                alignItems: "normal"
            },
            logo: {
                width: "12.07vw",
                height: "12.07vw",
                marginTop: "4.83vw",
                background: "transparent",
                borderRadius: "1.21vw",
                opacity: "1",
                objectFit: "contain",
            },
            tag: {
                padding: "1.43vw 4.83vw",
                height: "fit-content",
                marginTop: "3.62vw",
                marginRight: "3.62vw",
                marginLeft: "auto",
                textAlign: "center",
                borderRadius: "6.04vw",
                fontWeight: "bold",
                fontSize: "2.90vw",
                color: Color.White,
            },
            titleWrapper: {
                height: "4.59vw",
                marginTop: "2.41vw"
            },
            title: {                
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "3.86vw",
                letterSpacing: "0px",
                color: Color.Black,
                opacity: "1",
                whiteSpace: "nowrap",
                maxWidth: "66.67vw",
                overflow: "hidden"
            },
            organizationWrapper: {
                height: "4.83vw",
                marginTop: "0.72vw"
            }, 
            organization: {
                textAlign: "left",
                fontSize: "3.62vw",
                letterSpacing: "0px",
                color: Color.Black,
                marginRight: "2.42vw",
                maxWidth: "71.01vw",
                overflow: "hidden"
            },
            disableIcon: {
                width: "3.38vw",
                height: "3.86vw",
                fill: Color.Red,
                marginRight: "1.21vw"
            },
            cvLessIcon: {
                width: "4.83vw",
                height: "4.83vw"
            },
            calendarIcon: {
                width: "2.42vw",
                height: "2.65vw",
                marginRight: "1.45vw"
            },
            applyText: {
                display: "flex",
                fontSize: "2.66vw",
                color: Color.MiddleBlack,
                marginTop: "6.14vw",
                alignItems: "center"
            },
            applyDate: {
                fontWeight: "bold",
                marginLeft: "1.21vw"
            },
            completeButton: {
                width: "36.23vw",
                height: "7.25vw",
                border: "2px solid " + Color.Blue,
                color: Color.Blue,
                background: "transparent",
                fontWeight: "bold",
                fontSize: "2.90vw",
                textTransform: "uppercase",
                borderRadius: "1.21vw",
                marginTop: "3.93vw"
            },
            daysLeft: {
                margin: "6vw 3.62vw 0 auto",
                color: Color.Red,
                fontWeight: "bold",
                fontSize: "3.62vw"
            }
        },
        noResults: {
            textAlign: "center" as "center",
            color: Color.SharpAsh,
            fontSize: "5vw",
            marginTop: "5vh",
            zero: {
                fontWeight: "bold",
                color: Color.DeepBlack,
            },
            wrapper: {
                borderRadius: "2.77vw",
                border: "1px solid " + Color.HarvestGold,
                backgroundColor: Color.LightGold,
                display: "flex",
                alignItems: "center" as "center",
                justifyContent: "center" as "center",
                padding: "2.41vh",
                color: Color.HarvestGold,
                margin: "1.5vh 5% 0 5%"
            },
            icon: {
                marginRight: "5.43vw",
                width: "8.33vw",
                height: "8.33vw"
            }
        },
        recommendedJobs: {
           position: "relative"
        },
        appliedJobsSeeAll: {  
            display: "none",          
            width: "14.74vw",
            height: "3.64vw",
            backgroundColor: Color.Blue,
            border: "none",
            borderRadius: "0.52vw",
            cursor: "pointer",
            color: Color.White,
            fontFamily: Font.Default,
            fontSize:"1.04vw",
            fontWeight: "bold" as "bold",
            marginLeft: "7.29vw"              
        },        
        baseWrapper: {
            display: "flex",
            flexDirection: "column" as "column",
            marginTop: "9.66vw"
        },
        leftWrapper: {            
            width: "90%",
            overflow: "hidden",
            marginTop: "auto",
            marginLeft: "4.83vw",
            marginRight: "4.83vw" 
        },
        rightWrapper: {
            display: "none",
            width: "19.94vw",
            marginLeft: "auto",
            marginTop: "7.44vw"
        },
        flippingWrapper: {
            display: "flex",
            flexDirection: "column"
        },
        verifyDialog: {
            position: "fixed" as "fixed",
            left: "0",
            right: "0",
            bottom: "0",
            top: "0",
            transition: "transform 0.3s ease-out",
            zIndex: "2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: {
                position: "absolute" as "absolute",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0",
                backgroundColor: Color.Black,
                opacity: 0.4
            },
            container: {
                position: "relative",
                backgroundColor: Color.White,
                borderRadius: "2.41vw",
                padding: "2.41vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "73.50%",
                height: "21.40%"
            },
            close: {
                position: "absolute" as "absolute",
                width: "6.28vw",
                height: "6.28vw",
                top: "2vw",
                right: "3vw",
                cursor: "pointer",
                zIndex: "3"
            },
            text: {
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "4vw",
                lineHeight: "1.5",
                width: "83%"   
            }
        }
    },
    desktop: {
        appliedJobs:{
            marginTop:"2.54vw",
            title: {
                fontSize: "1.56vw",
                marginLeft: "7.29vw"
            },
            redirectButton: {
                display: "none"
            },
            slider: {                        
                margin: "1.04vw -6.8vw 2.4vw 6.8vw",
                width: "171.67vw"
            },
            grid: {            
                gridTemplateColumns: "repeat(4, 21.44vw)",            
                margin: "2.60vw 6.31vw 1.82vw 7.29vw"
            }
        },
        application: {
            height: "12.29vw",
            width: " 20.72vw",
            marginBottom: "1.48vh",
            borderRadius: "0.52vw",
            wrapper: {
                paddingLeft: "1.04vw"
            },
            logo: {
                width: "3.91vw",
                height: "3.91vw",
                borderRadius: "0.52vw",
                marginTop: "1.04vw"
            },
            tag: {
                padding: "0.364vw 1.04vw",
                marginTop: "1.04vw",
                marginRight: "1.04vw",
                fontSize: "0.72vw",
            },
            titleWrapper: {
                height: "1.25vw",
                marginTop: "0.52vw"
            },
            title: {
                fontSize: "1.04vw",
                maxWidth: "14.375vw"                
            },
            organizationWrapper: {
                height: "1.20vw",
                marginTop: "0.15vw"
            },
            organization: {
                fontSize: "0.94vw",
                marginRight: "0.78vw",
                maxWidth: "15.78vw"                
            },
            disableIcon: {
                width: "0.83vw",
                height: "0.99vw",                
                marginRight: "0.52vw"                
            },
            cvLessIcon: {
                width: "1.20vw",
                height: "1.20vw"
            },
            calendarIcon: {
                width: "0.62vw",
                height: "0.76vw",
                marginRight: "0.62vw"
            },
            applyText: {
                fontSize: "0.78vw",
                marginTop: "1.44vw"
            },
            applyDate: {
                marginLeft: "0.26vw"
            },
            completeButton: {
                width: "10.16vw",
                height: "1.82vw",
                border: "3px solid " + Color.Blue,
                fontSize: "0.78vw",
                marginTop: "0.95vw",
                borderRadius: "0.26vw"
            },
            daysLeft: {
                margin: "1.4vw 1.04vw 0 auto",
                fontSize: "0.72vw"
            }            
        },
        noResults: {            
            fontSize: "1.3vw",
            marginTop: "13.6vh",
            wrapper: {
                width: "49.47vw",
                borderRadius: "0.7vw",
                margin: "3.2vh auto auto auto"
            },
            icon: {
                marginRight: "1.43vw",
                width: "2.19vw",
                height: "2.19vw"
            }
        },
        appliedJobsSeeAll: {
            display: "block" 
        },
        baseWrapper: {
            flexDirection: "row" as "row",
            marginTop:"3.32vw",
            marginLeft: "7.29vw",
            marginRight:"7.29vw",
            fontSize:"1.04vw"
        },
        jobCategories: {
            marginTop: "6.4vw"
        },
        leftWrapper: {
            width: "auto",
            marginLeft: "0",
            marginRight: "0",
            marginTop: "0"      
        },
        noSavedJobsWrapper: {
            width: "100%",
            marginBottom:"-11vw" 
        },
        rightWrapper: {
            display: "block"   
        },
        flippingWrapper: {
            flexDirection: "column-reverse"
        },
        verifyDialog: {
            container: {
                borderRadius: "0.52vw",
                height: "19.20%",
                width: "57.50%",
                padding: "1vw 3vw"
            },
            close: {
                borderRadius: "50%",
                width: "1.8vw",
                height: "1.8vw",
                top: "0.73vw",
                right: "0.73vw"
            },
            text: {
                fontSize: "1.10vw",
                lineHeight: "1.5vw",
                width: "85%"
            }
        }
    }
}