import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Icon } from '../../assets/svg/search.svg';
import { Button } from './Buttons/Button';
import { LocationSelect } from './LocationSelect';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { SectorSelect } from './SectorSelect';

interface Props {     
    placeholder?: string;
    inputValue: string;
    setInputValue(inputValue: string): void;
    selectEnable?: boolean;
    selectedLocationIds?: string;
    selectedSectorIds?: string;
    setSelectedLocationIds?(selectedIds: string): void;
    setSelectedSectorIds?(selectedIds: string): void;
    setSelectedNames?(selectedNames: string): void;
    buttonText?: string;         
    handleSearch?(event: React.ChangeEvent<HTMLFormElement>): void;    
    isJobsPage?: boolean;
};

export const SearchArea: React.FC<Props> = (props) => {
    const [locationHovered, setLocationHovered] = useState<boolean>(false);
    const [sectorHovered, setSectorHovered] = useState<boolean>(false);
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("searchArea"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {
        let style = styleContext.getComponentStyle("searchArea");
        setStyles(style);
    }, [isMobile]);
    
    return (
        <>
            <form style={{...styles, ...((locationHovered || sectorHovered) && styles.overlay), ...(props.isJobsPage && styles.jobsMargin)}} onSubmit={props.handleSearch}>            
                <input style={styles.input} placeholder={props.placeholder} value={props.inputValue} onChange={event => props.setInputValue(event.currentTarget.value)}/>
                <hr style={styles.horizontalLine}/>
                {props.selectEnable && (
                    <>
                        <SectorSelect styles={styles.select} default={"Job Category"} selectedIds={props.selectedSectorIds!} getSelectedIds={props.setSelectedSectorIds} setSelectHovered={setSectorHovered} />
                        <hr style={styles.horizontalLine}/>
                        <LocationSelect styles={styles.select} default={"Location"} selectedIds={props.selectedLocationIds!} getSelectedIds={props.setSelectedLocationIds} getSelectedNames={props.setSelectedNames} setSelectHovered={setLocationHovered}/>
                    </>
                )}
                <Button type="submit" style={{...styles.button, ...(props.isJobsPage && styles.button.green)}}>
                    <div style={styles.button.buttonWrapper}>
                        <div style={styles.button.buttonText}>{props.buttonText}</div>
                        <Icon style={styles.button.buttonIcon} />
                    </div>                
                </Button>
            </form>            
        </>
    );
}