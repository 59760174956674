import { useEffect, useRef, useState } from "react"
import { getAllSectors } from "../../services/JobService";
import { Sector } from "../../models/Sector";
import { Option } from '../../models/SelectInputs';
import useErrorPage from "../../hooks/useErrorPage";
import { ReactComponent as DropDownIcon } from '../../assets/svg/drop_down.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import { DropdownItem } from "./DropdownItem";
import { ReactComponent as CheckMark } from '../../assets/svg/checkmark.svg';

interface Props {
    styles: any;
    default: string;
    selectedIds: string;
    getSelectedIds?(selectedIds: string): void;
    setSelectHovered(hovered: boolean): void;
}

export  const SectorSelect : React.FC<Props> = (props) => {
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [selectedCount, setSelectedCount] = useState<number>(0);
    const [selectedValue, setSelectedValue] = useState<string>(props.default);
    const [options, setOptions] = useState<Option[]>([]);
    const [filteredSectors, setFilteredSectors] = useState<Option[]>([]);
    const [hovered, setHovered] = useState<boolean>(false);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [toErrorPage] = useErrorPage();
    const inputRef = useRef<HTMLInputElement>(null);
    const styles = props.styles;
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);

    useEffect(() => {
        getSectorList();
    }, []);

    useEffect(() => {
        createOptionList();
    }, [props.selectedIds, sectors]);

    async function getSectorList() {
        await getAllSectors()
        .then((sectorList : Sector[]) => {
            let tempSectorList: Sector[] = sectorList.filter(sector => sector.hasJobs).sort((a, b) => a.friendlyName.localeCompare(b.friendlyName));
            setSectors(tempSectorList);
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }
    
    const createOptionList = () => {
        const tempOptions: Option[] = [];
        let tempSelectedCount: number = 0;
        const selectedIds = props.selectedIds !== undefined ? props.selectedIds.split(",").map(Number) : [];
        sectors.forEach((sector) => {
            let isSelected: boolean = selectedIds.includes(sector.sectorId);
            if (isSelected) {
                tempSelectedCount++;                
            }
            let options: Option = {
                value: sector.sectorId,
                option: sector.sectorName,
                checked: isSelected
            }
            tempOptions.push(options);
        });
        if(tempSelectedCount !== 0) {
            setSelectedCount(tempSelectedCount);
            setSelectedValue(tempSelectedCount + " selected");
        } else {
            setSelectedValue(props.default);
        }
        setOptions(tempOptions);
        setFilteredSectors(tempOptions);
    }

    const itemChecked = (sectorId: any) => {
        let tempOptions = [...options];
        let tempSelectedCount: number = selectedCount;
        const checkedOption = tempOptions.find(x => x.value == sectorId);
        checkedOption!.checked = !checkedOption!.checked;
        if(checkedOption!.checked) {
            tempSelectedCount++;
        } else {
            tempSelectedCount--;
        }
        if(tempSelectedCount == 0) {
            setSelectedValue(props.default);
        } else {
            setSelectedValue(tempSelectedCount + " selected");
        }
        setSelectedCount(tempSelectedCount);
        setOptions(tempOptions);
    }

    const sendSelected = () => {
        setHovered(false);
        props.setSelectHovered(false);
        let selectedIds: string = "";
        options.forEach((option) => {
            if(option.checked) {
                selectedIds = selectedIds + option.value + ",";
            }
        });
        selectedIds = selectedIds.substring(0, selectedIds.length - 1);
        props.getSelectedIds!(selectedIds);
    }

    const filterSectors = (keyword: string) => {
        setSearchKeyword(keyword);
        var tempOptions = options.filter(x => x.option.toUpperCase().includes(keyword.toUpperCase()));
        setFilteredSectors(tempOptions);
    }

    const setSelectHovered = () => {
        setHovered(true);
        props.setSelectHovered(true);
    }

    const onButtonClick = () => {
        if(hovered) {
            sendSelected();
        }
        else {
            setSelectHovered();
        }
    }

    const focusInput = (event: React.MouseEvent) => {
        event.stopPropagation()
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    return (
        <div style={{...styles.wrapper, ...styles.wrapper.sector}}>
            <div style={styles.button}
                onMouseEnter={setSelectHovered}
                onMouseLeave={sendSelected}
                onClick={onButtonClick}
            >
                <div style={styles.buttonWrapper}>
                    <div style={styles.buttonText}>{selectedValue}</div>
                    <DropDownIcon style={{...styles.buttonIcon, ...(hovered && styles.buttonIcon.hover)}}/>
                </div>
                <div style={hovered ? ({...styles.hover, ...styles.hover.sector}) : styles.leave}>
                    <input type="text" ref={inputRef} style={styles.search} placeholder={"Search"} onClick={e => {e.stopPropagation()}} onChange={(e) => filterSectors(e.target.value)}/>
                    {searchKeyword === "" && <div style={styles.search.iconWrapper}>
                        <SearchIcon style={styles.search.icon} onClick={(e) => focusInput(e)}/>
                    </div>}
                    <div className="scrollArea optionSelectScroll" style={styles.scrollArea}>
                        {filteredSectors.map((option, index) =>
                            <div
                                key={index}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(-1)}
                            >
                                <DropdownItem key={index} styles={styles.option} onClick={() => itemChecked(option.value)}>
                                    {option.checked ? <CheckMark style={styles.checkMark}/>
                                        : <div style={styles.checkbox}></div>}
                                    <label style={styles.optionLabel}>{option.option}</label>
                                </DropdownItem>
                            <hr style={{...styles.bottomBorder, ...((hoveredIndex === index || hoveredIndex === index + 1 || filteredSectors.length === index + 1) && styles.hoveredBorder)}}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}