import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as DropDownIcon } from '../../assets/svg/drop_down.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import { ReactComponent as CheckMark } from '../../assets/svg/checkmark.svg';
import { DropdownItem } from './DropdownItem';
import { getLocations } from '../../services/HomeService';
import { GeoLocation } from '../../models/GeoLocation';
import { Option } from '../../models/SelectInputs';
import useErrorPage from '../../hooks/useErrorPage';

interface Props {
    styles: any;  
    default: string;    
    selectedIds: string;
    getSelectedIds?(selectedIds: string): void;
    getSelectedNames?(selectedNames: string): void;
    setSelectHovered(hovered: boolean): void;
}

export const LocationSelect: React.FC<Props> = (props) => {  
    const styles = props.styles; 
    const [hovered, setHovered] = useState<boolean>(false);
    const [options, setOptions] = useState<Option[]>([]);
    const [locations, setLocations] = useState<GeoLocation[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>(props.default);
    const [selectedCount, setSelectedCount] = useState<number>(0);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [filteredLocations, setFilteredLocations] = useState<Option[]>([]);
    const [toErrorPage] = useErrorPage();    
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
    const inputRef = useRef<HTMLInputElement>(null);
    
    useEffect(() => {                     
        getLocationList();                
    }, []);

    useEffect(() => {                     
        createOptionList();                
    }, [props.selectedIds, locations]);

    async function getLocationList() {
        await getLocations()
        .then((locationList: GeoLocation[]) => {
            setLocations(locationList);
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    } 

    const createOptionList = () => {                
        const tempOptions = new Array<Option>();
        let tempSelectedCount: number = 0;
        const selectedIds = props.selectedIds !== undefined ? props.selectedIds.split(",").map(Number) : [];
        setSelectedCount(0);     
        locations.forEach((location) => { 
            let isSelected: boolean = selectedIds.includes(location.locationId);
            if(isSelected) {
                tempSelectedCount++;
            }           
            let option: Option = {
                value: location.locationId,
                option: location.locationName,
                checked: isSelected
            }           
            tempOptions.push(option);
        });
        if(tempSelectedCount !== 0) {
            setSelectedCount(tempSelectedCount);
            setSelectedValue(tempSelectedCount + " selected");
        }
        else {
            setSelectedValue(props.default);
        }
        setOptions(tempOptions);
        setFilteredLocations(tempOptions);  
    }

    const itemChecked = (locationId: any) => {
        let tempOptions = [...options];
        let tempSelectedCount: number = selectedCount;
        var checkedOption = tempOptions.find(x => x.value === locationId);
        checkedOption!.checked = !checkedOption!.checked; 
        if(checkedOption!.checked) {
            tempSelectedCount++;
        } else {
            tempSelectedCount--;
        } 
        if(tempSelectedCount === 0) {
            setSelectedValue(props.default);
        } else {
            setSelectedValue(tempSelectedCount + " selected");
        }    
        setSelectedCount(tempSelectedCount);
        setOptions(tempOptions);
    }

    const sendSelected = () => {
        setHovered(false);
        props.setSelectHovered(false);
        let selectedIds: string = "";
        let selectedNames: string = "in-";
        options.forEach((option) => {
            if(option.checked) {
                selectedIds = selectedIds + option.value + ",";
                selectedNames = selectedNames + option.option + "-";
            }
        });
        selectedIds = selectedIds.substring(0, selectedIds.length - 1);
        selectedNames = selectedNames.substring(0, selectedNames.length - 1);
        props.getSelectedIds!(selectedIds);
        if(selectedNames !== "in") {
            props.getSelectedNames!(selectedNames);
        }
        else {
            props.getSelectedNames!("");
        }
    }

    const filterLocations = (keyword: string) => {
        setSearchKeyword(keyword);
        var tempOptions = options.filter(x => x.option.toUpperCase().includes(keyword.toUpperCase()));
        setFilteredLocations(tempOptions);
    }

    const setSelectHovered = () => {
        setHovered(true);
        props.setSelectHovered(true);
    }

    const onButtonClick = () => {        
        if(hovered) {
            sendSelected();
        }
        else {
            setSelectHovered();
        }
    }

    const focusInput = (event: React.MouseEvent) => {
        event.stopPropagation()
        if (inputRef.current) {
          inputRef.current.focus();
        }
    };

    return (
        <div style={{...styles.wrapper, ...styles.wrapper.location}}>            
            <div style={styles.button} 
                onMouseEnter={setSelectHovered}
                onMouseLeave={sendSelected}
                onClick={onButtonClick}
            >                
                <div style={styles.buttonWrapper}>
                    <div style={styles.buttonText}>{selectedValue}</div> 
                    <DropDownIcon style={{...styles.buttonIcon, ...(hovered && styles.buttonIcon.hover)}}/>                    
                </div>
                <div style={hovered ? ({...styles.hover, ...styles.hover.location}) : styles.leave}> 
                    <input type="text" ref={inputRef} style={styles.search} placeholder={"Search"} onClick={e => {e.stopPropagation()}} onChange={(e) => filterLocations(e.target.value)}/>
                    {searchKeyword === ""  && <div style={styles.search.iconWrapper}>
                        <SearchIcon style={styles.search.icon} onClick={(e) => focusInput(e)} />
                    </div>}
                    <div className="scrollArea optionSelectScroll" style={styles.scrollArea}>
                        {filteredLocations.map((option, index) =>                    
                            <div 
                                key={index}
                                onMouseEnter={()=>{ setHoveredIndex(index); }}
                                onMouseLeave={()=>{ setHoveredIndex(-1); }} 
                            >
                                <DropdownItem key={index} styles={styles.option} onClick={() => itemChecked(option.value)}>
                                    {option.checked ? <CheckMark style={styles.checkMark} /> 
                                        : <div style={styles.checkbox}></div>}
                                    <label style={styles.optionLabel}>{option.option}</label>
                                </DropdownItem>
                            <hr style={{ ...styles.bottomBorder, ...((hoveredIndex === index || hoveredIndex === index + 1 || filteredLocations.length === index + 1) && styles.hoveredBorder) }} />
                            </div>
                        )}
                    </div>
                </div>              
            </div>
        </div>
    );
}