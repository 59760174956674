import React, { useMemo, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF, InfoWindowF } from "@react-google-maps/api";
import { Color } from '../../enums/Color';
import { GoogleMapTexts as Texts } from '../../helpers/LayoutTexts';

export interface Props {
    styles?: any;
    lat: number;
    lng: number;
    zoom: number;
};

export type Coordinates = {
    lat: number;
    lng: number;
};

export const GoogleMaps: React.FC<Props> = (props) => {
    const styles = props.styles;
    const googleMapsApiKey = "AIzaSyDc1aDe9a9PfwlId0XWyJogXS_9OtDbPfQ";//process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
    const center = useMemo(() => ({ lat: props.lat, lng: props.lng }), []);
    const [selectedMarker, setSelectedMarker] = useState<any>(null);
    const {isLoaded} = useLoadScript({ googleMapsApiKey: googleMapsApiKey });

    if (googleMapsApiKey === undefined) {
        return (<div>Error</div>);
    }

    if (!isLoaded) {
        return (<div>Loading...</div>);
    } 
    
    const mIcon = {
        url: Texts.markerURL
    };
     
    const customMarkerStyle = {
        text: "XpressJobs",
        color: Color.DarkRed,
        fontWeight:"600",
        fontSize:styles.mapFont.fontSize, 
        className:"customMarkerStyle"
    };

    const customMapStyle = [
        {
            featureType: "poi.government",
            elementType: "labels",
            stylers: [{ visibility: "off" }] 
        }
    ];

    const handleMarkerClick = (marker: any) => {
        setSelectedMarker(marker);
    };
 
    return (            
        <div style={styles}>
            <GoogleMap zoom={props.zoom} center={center} mapContainerClassName="google-maps" mapContainerStyle={styles.container} options={{ styles: customMapStyle }}>
                <MarkerF position={center} icon={mIcon} label= {customMarkerStyle}  onClick={() => {handleMarkerClick(center);}}>
                    {selectedMarker && (
                        <InfoWindowF
                        position={center}
                        onCloseClick={() => setSelectedMarker(null)} >
                            <div style={styles.infoWrapper}>
                                 <b>XpressJobs</b>
                                 No 2-2/1, Temple Lane<b/>
                                 Colombo 00300<b/>
                                 Sri Lanka<b/>
                                 <a style = {styles.googleMapLink} href={Texts.locationURL}>View on Google Maps</a>
                            </div>
                        </InfoWindowF>
                    )}  
                </MarkerF>
            </GoogleMap>
        </div>
    );         
}
