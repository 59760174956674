import { useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { VerticalJobDetailCard } from '../components/Shared/VerticalJobDetailCard';
import { JobViewLoader } from '../components/Loaders/JobViewLoader';
import { Footer } from '../components/Shared/Footer';
import { Header } from '../components/Shared/Header';
import { JobViewTexts as Texts } from '../helpers/LayoutTexts';
import { ReactComponent as DropDownIcon } from '../assets/svg/drop_down.svg';
import { JobView } from '../models/JobView';
import { StyleContext } from '../providers/StyleContextProvider';
import { getPublishedJob, getRelatedJobs, updateJobRedirect, updateJobViewCount } from '../services/JobService';
import { JobApply } from '../components/JobView/JobApply';
import { Share } from '../components/Shared/Share';
import { JobOrganizationCard } from '../components/Shared/JobOrganizationCard';
import { HorizontalBanner } from '../components/Shared/HorizontalBanner';
import { VerticalBanner } from '../components/Shared/VerticalBanner';
import { HorizontalJobDetailCard } from '../components/Shared/HorizontalJobDetailCard';
import { AuthContext } from '../providers/AuthContextProvider';
import useErrorPage from '../hooks/useErrorPage';
import useSaveJob from '../hooks/useSaveJob';
import { JobSectors } from '../components/JobView/JobSectors';
import { Helmet } from 'react-helmet-async';
import { ShortJobCardList } from '../components/Shared/ShortJobCardList';
import { LoggedCandidate } from '../models/LoggedCandidate';
import { createJobStructuredData } from '../helpers/Common';
import { InstagramEmbed } from '../components/JobView/InstagramEmbed';
import { ReactComponent as CloseCircle } from '../assets/svg/close-circle.svg';

export const JobViewPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const params = useParams();
    const jobId = +params.jobId!;
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("jobViewPage"));
    const [isJobAdExpand, setIsJobAdExpand] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [jobView, setJobView] = useState<JobView>();
    const [isBannerOffed, setIsBannerOffed] = useState<boolean>(false);
    const [jobVideoUrl, setJobVideoUrl]= useState<string>("");
    const [videoSource, setVideoSource] = useState<any>("");
    const horizontalCardEnablePoint: number = 600;
    const [horizontalCardEnable, setHorizontalCardEnable] = useState<boolean>(false);
    const stateRef = useRef<boolean>();
    stateRef.current = horizontalCardEnable;
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const relatedJobCount: number = isMobile ? 10 : 24;
    const [toErrorPage] = useErrorPage();
    const {saveCandidateJob, isSavedJob} = useSaveJob();
    const [isSaved, setIsSaved] = useState<boolean>(isSavedJob(jobId));
    const applyEnable = searchParams.get("applyEnable") !== 'false';
    const [structuredData, setStructuredData]  = useState<any>(null);
    const [jobArchivedStatus, setJobArchivedStatus] = useState<boolean>(false);
    const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(true);

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("jobViewPage"));
    }, [isMobile]);

    useEffect(() => {
        getJobView();
        document.addEventListener("scroll", onScroll);
        return () => {
            document.removeEventListener("scroll", onScroll);
        }
    }, [jobId]);

    async function getJobView() {
        setJobVideoUrl("");
        setVideoSource("");
        await getPublishedJob(jobId)
        .then((publishedJob: JobView) => {
            if (publishedJob) {
                setJobView(publishedJob);
                setIsBannerOffed(publishedJob.organization.isBannerOffed);
                setStructuredData(createJobStructuredData(publishedJob));
                updateViewCount(jobId);
                setJobArchivedStatus(publishedJob.jobItem.archived);
                if (publishedJob.jobItem.videoUrl) {
                    setVideoUrlAndType(publishedJob.jobItem.videoUrl);
                }
                setLoading(false);
            } else {
                navigate('/jobs');
            }
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }

    async function getRelatedJobList() {
        return getRelatedJobs(jobId, relatedJobCount);        
    }

    const updateViewCount = (jobId: number) => {
        updateJobViewCount(jobId);
    }

    const onScroll = () => {
        if ((window.scrollY < horizontalCardEnablePoint) && stateRef.current) {
            setHorizontalCardEnable(false);
        } else if ((window.scrollY > horizontalCardEnablePoint) && !stateRef.current) {
            setHorizontalCardEnable(true);
        }
    }

    const handleJobAdButtonClick = () => {
        setIsJobAdExpand((prev) => !prev);
    }

    const handleApplyButtonClick = () => {
        if (jobView?.isRedirected) {
            updateJobRedirect(jobView?.jobId)
            .then((response: boolean) => { })
            .catch((error) => {
                toErrorPage(error);
            });
            window.open(jobView?.redirectingUrl, '_blank');
        } else if (jobView?.jobItem.isPreApplicationFormEnabled) {
            navigate("/jobs/preapplicationform/" + jobView?.jobId);
        } else {
            if (candidate === null) {
                const params = { callbackUrl: "/jobs/apply/" + jobView?.jobId, message: "Please login or register to apply this job." };
                navigate({
                    pathname: '/candidates/login',
                    search: `?${createSearchParams(params)}`
                });
            } else {
                navigate("/jobs/apply/" + jobView?.jobId);
            }
        }
    }

    const saveJob = (jobId: number) => {
        saveCandidateJob(jobId, isSaved);
        setIsSaved(!isSaved);
    }

    const setVideoUrlAndType = (url: string) => {
        let embedUrl = '';
        let videoType = '';
        if (url.includes('youtube.com') || url.includes('youtu.be')) {
            const videoId = new URL(url).searchParams.get('v') || url.split('/').pop();
            embedUrl = `https://www.youtube.com/embed/${videoId}`;
            videoType = 'youtube';
        } else if (url.includes('youtube.com/shorts/')) {
            const videoId = url.split('/').pop();
            embedUrl = `https://www.youtube.com/embed/${videoId}`;
            videoType = 'youtubeShort';
        } else if (url.includes('facebook.com') && url.includes('/reel/')) {
                const reelId = url.split('/reel/')[1]?.split('/')[0];
                embedUrl = `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(`${url}`)}&show_text=0`;
                videoType = 'facebookReel';
        } else if (url.includes('linkedin.com')) {
            const postId = url.split('activity-')[1].split('-')[0];
            embedUrl = `https://www.linkedin.com/embed/feed/update/urn:li:activity:${postId}`;
            videoType = 'linkedIn';
        } else if (url.includes('tiktok.com')) {
            const videoId = url.split('/video/')[1].split('?')[0];
            embedUrl = `https://www.tiktok.com/embed/${videoId}`;
            videoType = 'tiktok';
        } else if (url.includes('instagram.com')) {
            embedUrl = url;
            videoType = 'instagram';
        } else if (url.includes('drive.google.com/file/d/')) {
            const fileId = url.split('/d/')[1]?.split('/')[0];
            embedUrl = `https://drive.google.com/file/d/${fileId}/preview`;
            videoType = 'drive';
        }
        setJobVideoUrl(embedUrl);
        setVideoSource(videoType);
    };

    const handleOpenAlertDialog = () => {
        setOpenAlertDialog(prev => !prev);
    }
   
    return (
        <>
            <Header styleSheet="jobViewHeader" searchEnable={false} jobTitle={jobView?.jobTitle} organization={jobView?.organization.organizationName} isDisabilityFriendly={jobView?.jobItem.disabilityFriendly} backButtonText={"Back"} jobSaved={isSaved} backButtonEnable={true} />
            {loading ? <JobViewLoader styles={styles} /> :
                <div>
                    <Helmet>
                        <title>{jobView?.jobTitle}</title>
                        <meta name="description" content={jobView?.jobTitle} />
                        <meta name="keywords" content={jobView?.metaKeywords} />
                        <meta name="og:title" content={jobView?.jobTitle} />
                        {structuredData && (<script type="application/ld+json" >{JSON.stringify(structuredData)}</script>)}
                    </Helmet>
                    {horizontalCardEnable && <HorizontalJobDetailCard styles={styles.horizontalDetailCard} job={jobView!.jobItem} isSaved={isSaved} applyEnable={applyEnable} handleApplyButton={handleApplyButtonClick} saveJob={saveJob} />}
                    <div style={styles.baseWrapper}>
                        <div style={styles.leftWrapper}>
                            {/* This is loading only mobile platform */}
                            <div style={styles.mobileDetailCard}>
                                <VerticalJobDetailCard job={jobView!.jobItem} isSaved={isSaved} applyEnable={applyEnable} education={jobView!.education} experience={jobView!.experience} salaryRange={jobView!.salaryRange} benefits={jobView!.benefits} handleApplyButtonClick={handleApplyButtonClick} saveJob={saveJob} />
                            </div>
                            <div style={styles.description}>
                                <div  className="jobviewDescription" dangerouslySetInnerHTML={{ __html: jobView?.jobInfo! }}/>
                            </div>
                            {jobView?.jobHasImage && <div style={{ ...styles.jobAdContainer, ...(isJobAdExpand && styles.jobAdContainer.expand) }}>
                                <div style={styles.expandButton} onClick={handleJobAdButtonClick}>
                                    {Texts.jobAd}<span style={styles.expandButton.iconWrapper}><DropDownIcon style={{ ...styles.expandButton.icon, ...(isJobAdExpand && styles.expandButton.expandIcon) }} /></span>
                                </div>
                                {isJobAdExpand && <img src={jobView?.jobImageUri} style={styles.jobAd} alt={jobView.jobTitle} />}
                            </div>}
                            {(!jobView?.jobItem.archived && applyEnable) && <JobApply styles={styles.apply} jobId={jobView!.jobId} isSaved={isSaved} isGovernmentJob={jobView?.jobItem.isGovernmentJob!} isGovernmentJobApplication={jobView?.jobItem.isGovernmentJobApplication!} handleApplyButtonClick={handleApplyButtonClick} saveJob={saveJob} />}
                            <br />
                            <div style={styles.share.wrapper}>
                                <div style={styles.share.text}>{Texts.unemployment}</div>
                                <Share styles={styles.share} jobId={jobView!.jobId} jobTitle={jobView!.jobTitle} organizationName={jobView!.organization.organizationName}/>
                            </div>
                            {jobVideoUrl && (<div style={styles.video}>
                            {videoSource === 'instagram' ? <InstagramEmbed url={jobVideoUrl} styles={{...styles.video[videoSource]}}/> : <iframe style={{...styles.video[videoSource]}} src={jobVideoUrl} allow="gyroscope; accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture" allowFullScreen></iframe>}
                            </div>)}
                        </div>
                        <div style={styles.rightWrapper}>
                            {/* This is loading only desktop platform */}
                            <div style={styles.desktopDetailCard}>
                                <VerticalJobDetailCard job={jobView!.jobItem} isSaved={isSaved} applyEnable={applyEnable} education={jobView!.education} experience={jobView!.experience} salaryRange={jobView!.salaryRange} benefits={jobView!.benefits} handleApplyButtonClick={handleApplyButtonClick} saveJob={saveJob} />
                            </div>
                            <JobSectors styles={styles.jobSectors} sectors={jobView!.sectors} />
                            <JobOrganizationCard styles={styles.organizationCard} organization={jobView!.organization} buttonText={"ADD TO FAVOURITE"} />
                            {!isBannerOffed && <div style={styles.verticalBanner}>
                                <VerticalBanner adsenseClient={Texts.adClient} adsenseSlotOne={Texts.verticalAdSlot}/>
                            </div>}
                        </div>
                    </div>
                </div>}
            {jobArchivedStatus && openAlertDialog && <div style={styles.alertDialog}>
                <div style={styles.alertDialog.background} onClick={handleOpenAlertDialog}></div>
                <div style={styles.alertDialog.container}>
                    <CloseCircle style={styles.alertDialog.close} onClick={handleOpenAlertDialog}/>
                    <div style={styles.alertDialog.text}>{Texts.alert}</div>
                </div>
            </div>}
            <ShortJobCardList title={'Related Jobs'} sortEnabled={false} loadJobs={getRelatedJobList}/>
            {!isBannerOffed && <div style={styles.horizontalBanner}>
                <HorizontalBanner adsenseClient={Texts.adClient} adsenseSlotOne={Texts.horizontalAdSlotOne} adsenseSlotTwo={Texts.horizontalAdSlotTwo}/>
            </div>}
            <Footer/>
        </>
    );
}