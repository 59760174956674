import React from 'react';
import { ReactComponent as Crown } from '../../assets/svg/crown.svg';

interface Props {
    styles: any;  
}

export const PremiumCard: React.FC<Props> = (props) => {
    const styles = props.styles;

    return (
        <div style={styles}>  
            <div>
                <Crown style={styles.crownIcon}/>
                <span style={styles.crownText}>{"Premium"}</span>
            </div>
            <div style={styles.subscription}>{"Yearly Subscription"}</div>            
            <div>
                <span style={styles.time}>{"1 Year"}</span>
                <span style={styles.price}>{"500 LKR"}</span>
            </div>
        </div>
    )
}