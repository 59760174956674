import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Header } from "../components/Shared/Header";
import { StyleContext } from "../providers/StyleContextProvider";
import { ReactComponent as LinkedIn } from '../assets/svg/linkedin.svg';
import { ReactComponent as Whatsapp } from '../assets/svg/whatsapp.svg';
import { ReactComponent as Facebook } from '../assets/svg/facebook.svg';
import { ReactComponent as Instagram } from '../assets/svg/instagram.svg';
import { ReactComponent as Twitter } from '../assets/svg/twitter.svg';
import { ReactComponent as FooterSvgV1 } from '../assets/svg/error-page-footer.svg';
import { ReactComponent as FooterSvgV2 } from '../assets/svg/error-page-building.svg';
import { ErrorPageTexts as Texts } from '../helpers/LayoutTexts';
import { Button } from "../components/Shared/Buttons/Button";
import { useNavigate } from "react-router-dom";

export const ErrorPage: React.FC = () => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("errorPage"));                
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
        
    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("errorPage"));
    }, [isMobile]);
    
    const backToHome = () => {
        navigate('/');
    }

    return (
        <>
            <Header styleSheet="errorHeader" searchEnable={false} backButtonEnable={false}/>
            <div style={styles.container}>
                {isMobile ? 
                <img style={styles.image} src={process.env.PUBLIC_URL + "/img/error-image-mobile.png"} alt="removed or temporarily unavailable page on mobile devices"/> :
                    <div style={styles.imgContainer}>
                        <div style={styles.flexChild}>  
                            <img style={styles.imageLeft} src={process.env.PUBLIC_URL + "/img/error-image-A.png"} alt="removed or temporarily unavailable page on desktop devices"/>
                        </div>
                        <div style={styles.flexChild}>
                            <img style={styles.imageRight} src={process.env.PUBLIC_URL + "/img/error-image-B.png"} alt="removed or temporarily unavailable page on desktop devices"/>
                        </div>
                    </div>
                }
                <div style={styles.oopsText}>{"Oops!"}</div>
                <div style={styles.connectText}>{Texts.connect}</div>
                <div style={styles.unavailableText}>{Texts.unavailable}</div>
                <Button style={styles.backButton} onClick={backToHome}>{"BACK TO HOME"}</Button>                
                <div style={styles.socialIconWrapper}>
                    <a href="https://www.linkedin.com/company/xpressjobs" target='_blank'>
                        <LinkedIn style={styles.icon}/>
                    </a>
                    <a href="https://whatsapp.com/channel/0029Vb4nUkP4dTnT42UW5X3j" target="_blank">
                        <Whatsapp style={styles.icon}/>
                    </a>
                    <a href="https://www.facebook.com/XpressJobs/" target='_blank'>
                        <Facebook style={styles.icon}/>
                    </a>
                    <a href="https://www.instagram.com/xpress.jobs/" target='_blank'>
                        <Instagram style={styles.icon}/>
                    </a>
                    <a href="https://twitter.com/xpress_jobs" target='_blank'>
                        <Twitter style={styles.icon}/>
                    </a>
                </div>
                <div style={styles.copyright}>{Texts.copyright}</div>
                {isMobile ? <FooterSvgV1 style={styles.footerSvg}/> : <FooterSvgV2 style={styles.footerSvg}/>}
            </div>
        </>
    );
}