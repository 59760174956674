import { Color } from "../enums/Color";

export const statusOfApplications = {
    mobile: {
        title: {
            color: Color.LightBlack,
            fontWeight: "bold",
            fontSize: "6.03vw",
            margin: "4.46vh 0 0 0",
        },
        topWrapper: {
            display: "flex",
            flexDirection: "row",
            marginTop: "1.79vh"
        },
        applicationCount: {
            alignSelf: "center",
            fontSize: "3.62vw",
            color: Color.SharpAsh,
            count: {
                fontWeight: "bold",
                color: Color.DeepBlack
            },
        },
        filterByWrapper: {
            display: "flex",
            flexDirection: "row",
            marginLeft: "auto",
            alignItems: "center",
        },
        filterByLabel: {
            fontSize: "3.62vw",
            marginRight: "3.62vw"
        },
        checkbox: {
            borderRadius: "0.5vw",            
            zIndex: 0,
            cursor: "pointer",
            border: "0.07vw solid " + Color.SharpAsh,
            height: "2.69vw",
            width: "2.69vw",
            float: "left",
            marginLeft: "2.17vw"
        },
        checkMark: {
            marginLeft: "2.17vw",            
            height: "3.2vw",
            width: "3.2vw",
            float: "left"
        },
        optionDot: {
            height: "3.38vw",
            width: "3.38vw",
            borderRadius: "50%",
            cursor: "pointer"
        },
        optionLabel: {
            marginLeft: "2.41vw",
            marginRight: "auto",
            color: Color.MiddleBlack,
            fontWeight: "500",
            fontSize: "3.86vw",
            whiteSpace: "nowrap",
            width: "73%",
            overflow: "hidden",            
            cursor: "pointer"
        },
        applicationsWrapper: {
            marginTop: "2.34vh"
        },
        application: {
            height: "40.34vw",
            width: "100%",
            borderRadius: "2.41vw",
            marginBottom: "2.41vw",
            transition: "all 0.3s ease-in-out",
            display: "inline-block",
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",
            hover: {
                background: Color.White,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.5)",
            },
            leave: {
                background: Color.LightAsh
            },
            link: {
                textDecoration: "none"
            },
            wrapper: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: "3.62vw"
            },
            logoWrapper: {
                alignItems: "normal"
            },
            logo: {
                width: "12.07vw",
                height: "12.07vw",
                marginTop: "4.83vw",
                background: "transparent",
                borderRadius: "1.21vw",
                opacity: "1",
                objectFit: "contain",
            },
            tag: {
                padding: "1.43vw 4.83vw",
                height: "fit-content",
                marginTop: "3.62vw",
                marginRight: "3.62vw",
                marginLeft: "auto",
                textAlign: "center",
                borderRadius: "6.04vw",
                fontWeight: "bold",
                fontSize: "2.90vw",
                color: Color.White,
            },
            titleWrapper: {
                height: "4.59vw",
                marginTop: "2.41vw"
            },
            title: {                
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "3.86vw",
                letterSpacing: "0px",
                color: Color.Black,
                opacity: "1",
                whiteSpace: "nowrap",
                maxWidth: "66.67vw",
                overflow: "hidden"
            },
            saveIcon: {
                width: "3.86vw",
                height: "3.86vw",
                marginLeft: "1.20vw",
                fill: Color.SharpAsh
            },
            savedIcon: {
                fill: Color.Blue
            },
            jobSaved: {
                color: Color.Blue,
                fontSize: "3.8vw",
                marginLeft: "1.11vw",
            },
            saveJobWrapper: {
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                background: "transparent",
                padding: 0                
            },
            organizationWrapper: {
                height: "4.83vw",
                marginTop: "0.72vw"
            }, 
            organization: {
                textAlign: "left",
                fontSize: "3.62vw",
                letterSpacing: "0px",
                color: Color.Black,
                marginRight: "2.42vw",
                maxWidth: "71.01vw",
                overflow: "hidden"
            },
            disableIcon: {
                width: "3.38vw",
                height: "3.86vw",
                fill: Color.Red,
                marginRight: "1.21vw"
            },
            cvLessIcon: {
                width: "4.83vw",
                height: "4.83vw"
            },
            calendarIcon: {
                width: "2.42vw",
                height: "2.65vw",
                marginRight: "1.45vw"
            },
            applyText: {
                display: "flex",
                fontSize: "2.66vw",
                color: Color.MiddleBlack,
                marginTop: "3.14vw",
                alignItems: "center"
            },
            applyDate: {
                fontWeight: "bold",
                marginLeft: "1.21vw"
            },
            completeButton: {
                width: "36.23vw",
                height: "7.25vw",
                border: "2px solid " + Color.Blue,
                color: Color.Blue,
                background: "transparent",
                fontWeight: "bold",
                fontSize: "2.90vw",
                textTransform: "uppercase",
                borderRadius: "1.21vw",
                marginTop: "1.93vw"
            },
            daysLeft: {
                margin: "3vw 3.62vw 0 auto",
                color: Color.Red,
                fontWeight: "bold",
                fontSize: "3.62vw"
            }
        },
        noResults: {
            textAlign: "center" as "center",
            color: Color.SharpAsh,
            fontSize: "5vw",
            marginTop: "5vh",
            zero: {
                fontWeight: "bold",
                color: Color.DeepBlack,
            },
            wrapper: {
                borderRadius: "2.77vw",
                border: "1px solid " + Color.HarvestGold,
                backgroundColor: Color.LightGold,
                display: "flex",
                alignItems: "center" as "center",
                justifyContent: "center" as "center",
                padding: "2.41vh",
                color: Color.HarvestGold,
                margin: "1.5vh 5% 4vh 5%"
            },
            icon: {
                marginRight: "5.43vw",
                width: "8.33vw",
                height: "8.33vw"
            }
        },
        loaderStyles: {
            wrapper: {
                margin: "0"
            }
        }
    },
    desktop: {
        title: {
            fontSize: "1.56vw",
            margin: "4.63vh 0 0 0.52vw"
        },
        topWrapper: {
            marginTop: "0.28vh"
        },
        applicationCount: {
            fontSize: "0.94vw",
            marginLeft: "0.52vw"
        },
        filterByWrapper: {},
        filterByLabel: {
            fontSize: "0.94vw",
            marginRight: "1.04vw"
        },
        filterBySelect: {
            wrapper: {
                padding: "0.52vw 1.66vw",
                width: "9.91vw",
                height: "1.043vw"
            },
            hover: {
                top: "2.083vw"
            }
        },
        optionDot: {
            width: "0.72vw",
            height: "0.72vw"
        },
        optionLabel: {
            marginLeft: "0.52vw",
            fontSize: "0.83vw"
        },
        applicationsWrapper: {
            marginTop: "2.96vh",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridColumnGap: "0.415vw"
        },
        application: {
            height: "10.93vw",
            width: " 20.72vw",
            marginBottom: "1.48vh",
            borderRadius: "0.52vw",
            wrapper: {
                paddingLeft: "1.04vw"
            },
            logo: {
                width: "3.91vw",
                height: "3.91vw",
                borderRadius: "0.52vw",
                marginTop: "1.04vw"
            },
            tag: {
                padding: "0.364vw 1.04vw",
                marginTop: "1.04vw",
                marginRight: "1.04vw",
                fontSize: "0.72vw",
            },
            titleWrapper: {
                height: "1.25vw",
                marginTop: "0.52vw"
            },
            title: {
                fontSize: "1.04vw",
                maxWidth: "14.375vw"                
            },
            saveJobWrapper: {                
                flexDirection: "row"
            },
            saveIcon: {
                width: "0.88vw",
                height: "0.98vw",
                marginLeft: "0.78vw"
            },
            jobSaved: {
                marginLeft: "0.2vw",
                fontSize: "0.83vw",
                marginRight: "0.95vw"
            },
            organizationWrapper: {
                height: "1.20vw",
                marginTop: "0.15vw"
            },
            organization: {
                fontSize: "0.94vw",
                marginRight: "0.78vw",
                maxWidth: "15.78vw"                
            },
            disableIcon: {
                width: "0.83vw",
                height: "0.99vw",                
                marginRight: "0.52vw"                
            },
            cvLessIcon: {
                width: "1.20vw",
                height: "1.20vw"
            },
            calendarIcon: {
                width: "0.62vw",
                height: "0.76vw",
                marginRight: "0.62vw"
            },
            applyText: {
                fontSize: "0.78vw",
                marginTop: "0.44vw"
            },
            applyDate: {
                marginLeft: "0.26vw"
            },
            completeButton: {
                width: "10.16vw",
                height: "1.82vw",
                border: "3px solid " + Color.Blue,
                fontSize: "0.78vw",
                marginTop: "0.26vw",
                borderRadius: "0.26vw"
            },            
            daysLeft: {
                margin: "0.5vw 1.04vw 0 auto",
                fontSize: "0.72vw"
            }           
        },
        checkbox: {
            marginLeft: "0.46vw",
            borderRadius: "0",            
            height: "0.57vw",
            width: "0.57vw"
        },
        checkMark: {
            marginLeft: "0.46vw",            
            height: "0.67vw",
            width: "0.67vw"
        },
        noResults: {            
            fontSize: "1.3vw",
            marginTop: "13.6vh",
            wrapper: {
                width: "75%",
                borderRadius: "0.7vw",
                margin: "3.2vh auto 7.81vw auto"
            },
            icon: {
                marginRight: "1.43vw",
                width: "2.19vw",
                height: "2.19vw"
            }
        }
    }
}